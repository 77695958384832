import CoreGetters from '@/fw-modules/fw-core-vue/store/getters'

export default Object.assign(CoreGetters, {
  userPermissions(state) {
    console.log('state.session.permissions', state.session.permissions)
    return {
      isAdmin: Array.isArray(state.session.permissions) ? state.session.permissions.includes('admin') : false,
      isManager: Array.isArray(state.session.permissions) ? state.session.permissions.includes('manager') : false,
      isMapsManager: Array.isArray(state.session.permissions)
        ? state.session.permissions.includes('manager-maps')
        : false,
      isEnrollmentsManager: Array.isArray(state.session.permissions)
        ? state.session.permissions.includes('manager-enrollments')
        : false
    }
  },

  getUserPermissions(state) {
    return state.session.permissions
  },

  isPermissionsLoaded(state) {
    return state.session.permissionsLoaded
  },

  getlastReceipt: state => type => {
    let data
    if (type == 'irs') data = state.lastIrs
    else data = state.lastPaySlip

    if (!data || !data.length) {
      data = JSON.parse(localStorage.getItem(`session.${type}`))
      if (!data || (!data.year && !data.month)) return {}
    }
    return data
  }
})
