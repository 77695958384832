<template>
  <BlockUserDetails v-if="userDetails" :user="userDetails" :loading="loading || loadingPermissions">
    <template #more-meta>
      <div class="flex flex-col gap-3">
        <div v-if="user.permissions">
          <fw-label marginless>Permissões</fw-label>
          <div :class="{ 'text-sm text-gray-500': !user.permissions.length }">
            {{ user.permissions.length ? user.permissions.join(', ') : 'Sem permissões atribuídas.' }}
          </div>
        </div>
      </div>
    </template>

    <div class="flex flex-col">
      <fw-panel
        :loading="loadingActivity"
        :title="'Atividade'"
        boxed
        class="my-3"
        custom-class="bg-gray-100 p-1.5"
        :see-more-path="activity.logs.length ? `/manage/activity?user=${user.key}` : null"
        :see-more-title="activity.logs.length ? 'Ver mais' : null"
        :subtitle="`Últimos ${activity.limit} registos.`"
      >
        <div v-if="!loadingActivity && activity.logs.length">
          <CardActivityLog
            v-for="log in activity.logs"
            :key="log.key"
            basic
            :row="false"
            :expandable="true"
            :log="log"
            :users="activity.users"
          ></CardActivityLog>
        </div>
        <fw-panel-info v-else type="basic" class="opacity-70 p-1"
          >Ainda não existe atividade registada para este utilizador.</fw-panel-info
        >
        <template #footer>
          <fw-tip v-if="activity.logs.length" class="opacity-80 p-1" marginless
            >Consulte todos os registos na seção de
            <a class="text-primary" href="/manage/activity">Atividade</a>.</fw-tip
          >
        </template>
      </fw-panel>
    </div>
  </BlockUserDetails>
</template>

<script>
import BlockUserDetails from '@/fw-modules/fw-core-vue/id/components/blocks/BlockUserDetails'
import CardActivityLog from '@/fw-modules/fw-core-vue/activity/components/cards/CardActivityLog'

export default {
  name: 'UserDetails',
  components: {
    BlockUserDetails,
    CardActivityLog
  },
  props: {
    user: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      loading: true,
      loadingPermissions: false,
      loadingActivity: true,
      activity: {
        logs: [],
        users: {},
        limit: 10
      }
    }
  },
  computed: {
    api() {
      return this.$store.state.api.base
    },
    loggedUser() {
      return this.$store.getters.getUser
    }
  },
  created() {
    this.getUserActivity()
  },
  methods: {
    async getUserActivity() {
      this.loadingActivity = true

      var searchPayload = {
        service: 'myuc',
        limit: this.activity.limit,
        users: true,
        by_user_key: this.user.key
      }

      const activity = await this.api.getActivityLogs(searchPayload)
      this.activity.users = activity.users
      this.activity.logs = activity.logs.map(v => {
        v.expanded = false
        return v
      })
      this.loadingActivity = false
    }
  }
}
</script>
