export default [
  {
    path: '/manage',
    name: 'manage',
    component: () => import('@/views/manage/ManageHome'),
    meta: { requiresAuth: true, requiresPermissions: true }
  },
  {
    path: '/manage/activity',
    name: 'manage-activity',
    component: () => import('@/views/manage/ManageActivity'),
    meta: { requiresAuth: true, requiresPermissions: true }
  },
  {
    path: '/manage/notifications',
    name: 'manage-notifications',
    component: () => import('@/views/manage/ManageNotifications'),
    meta: { requiresAuth: true, requiresPermissions: true }
  },
  {
    path: '/manage/vacations',
    name: 'manage-vacations',
    component: () => import('@/views/manage/ManageVacationsHome'),
    meta: { requiresAuth: true, requiresPermissions: true }
  },
  {
    path: '/manage/attendances',
    name: 'manage-attendances',
    component: () => import('@/views/manage/ManageAttendances'),
    meta: { requiresAuth: true, requiresPermissions: true }
  },
  {
    path: '/manage/vacations/:year',
    name: 'manage-vacations-year',
    component: () => import('@/views/manage/ManageVacationsYear'),
    meta: { requiresAuth: true, requiresPermissions: true, view: 'dashboard' }
  },
  {
    path: '/manage/vacations/:year/maps',
    name: 'manage-vacations-year-maps',
    component: () => import('@/views/manage/ManageVacationsYear'),
    meta: { requiresAuth: true, requiresPermissions: true, view: 'maps' }
  },
  {
    path: '/manage/vacations/:year/inbox',
    name: 'manage-vacations-year-inbox',
    component: () => import('@/views/manage/ManageVacationsYear'),
    meta: { requiresAuth: true, requiresPermissions: true, view: 'inbox' }
  },
  {
    path: '/manage/vacations/:year/sync',
    name: 'manage-vacations-year-sync',
    component: () => import('@/views/manage/ManageVacationsYear'),
    meta: { requiresAuth: true, requiresPermissions: true, view: 'sync' }
  },
  {
    path: '/manage/vacations/:year/sync/rights',
    name: 'manage-vacations-year-sync-logs',
    component: () => import('@/views/manage/ManageVacationsYear'),
    meta: { requiresAuth: true, requiresPermissions: true, view: 'sync-rights-results' }
  },
  {
    path: '/manage/vacations/:year/activity',
    name: 'manage-vacations-year-activity',
    component: () => import('@/views/manage/ManageVacationsYear'),
    meta: { requiresAuth: true, requiresPermissions: true, view: 'activity' }
  },
  {
    path: '/manage/vacations/:year/notifications',
    name: 'manage-vacations-year-notifications',
    component: () => import('@/views/manage/ManageVacationsYear'),
    meta: { requiresAuth: true, requiresPermissions: true, view: 'notifications' }
  },
  {
    path: '/manage/vacations/:year/settings',
    name: 'manage-vacations-year-settings',
    component: () => import('@/views/manage/ManageVacationsYear'),
    meta: { requiresAuth: true, requiresPermissions: true, view: 'settings' }
  },
  {
    path: '/manage/vacations/:year/maps/:key',
    name: 'manage-map-users',
    component: () => import('@/views/manage/ManageMapUsers'),
    meta: { requiresAuth: true, requiresPermissions: true }
  },
  {
    path: '/manage/vacations/:year/maps/:key/user/:userKey/:mapUserKey',
    name: 'manage-map-user',
    component: () => import('@/views/manage/ManageMapUser'),
    meta: { requiresAuth: true, requiresPermissions: true }
  },
  {
    path: '/manage/justifications',
    name: 'manage-justifications',
    component: () => import('@/views/manage/ManageJustifications'),
    meta: { requiresAuth: true, requiresPermissions: true }
  },
  {
    path: '/manage/justification/:key',
    name: 'manage-absence-justification',
    component: () => import('@/views/manage/ManageJustification'),
    meta: { requiresAuth: true, requiresPermissions: true }
  },
  {
    path: '/manage/justification/:key/:section',
    name: 'manage-absence-justification-sections',
    component: () => import('@/views/manage/ManageJustification'),
    meta: { requiresAuth: true, requiresPermissions: true }
  },
  {
    path: '/manage/courses',
    name: 'manage-courses',
    component: () => import('@/views/manage/ManageCourses'),
    meta: { requiresAuth: true, requiresPermissions: true }
  },
  {
    path: '/manage/courses/:key',
    name: 'manage-course-editions',
    component: () => import('@/views/manage/ManageCourse'),
    meta: { requiresAuth: true, requiresPermissions: true }
  },
  {
    path: '/manage/courses/:key/editions/:editionKey/certificates',
    name: 'manage-course-edition-enrollments',
    component: () => import('@/views/manage/ManageCourseEditionEnrollments'),
    meta: { requiresAuth: true, requiresPermissions: true }
  },
  {
    path: '/manage/events/:key',
    name: 'manage-event',
    component: () => import('@/views/manage/events/ManageEvents'),
    meta: { requiresAuth: true }
  },
  {
    path: '/manage/events/:key/sessions/:sessionKey',
    name: 'manage-event-session',
    component: () => import('@/views/manage/events/ManageEvents'),
    meta: { requiresAuth: true }
  }
]
